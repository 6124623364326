.partner-page-breadcrumb {
  padding: 32px 0;
}
.banner-container {
  padding: 0 12px;
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
  margin-bottom: 56px;
  .partner-img-wrapper {
    width: 100%;
    height: 322px;
    img {
      height: inherit;
      width: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }
  .partner-logo-container {
    .partner-logo-wrapper {
      width: 216px;
      height: 116px;
      padding: 0.5rem;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
        0px 1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      background-color: $white;
      position: absolute;
      left: 32px;
      bottom: -16px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .share-icon {
      display: none;
    }
  }
}
.desc-partner-wrapper {
  .btn {
    color: $info-500;
  }
  p {
    line-height: 28px;
  }
  .partner-snapshot-wrapper {
    background-color: $light-200;
    border-radius: 6px;
    align-items: center;
    padding: 16px 20px;
    gap: 12px;
    margin-top: 48px;
    .icon-wrapper {
      @extend .d-flex,
        .flex-column,
        .justify-content-center,
        .align-items-center;
      cursor: pointer;
      width: 424px;
      height: 120px;
      border-radius: 6px;
      &:hover {
        background-color: $primary-700;
        .partner-title,
        svg {
          color: $white;
        }
        .partner-count {
          color: $light-700;
        }
      }
      .pgn__icon {
        width: 40px;
        height: 40px;
      }
      .partner-title {
        font-size: $font-size-base-sm;
        color: $gray-500;
      }
      .partner-count {
        font-weight: bold;
        color: $gray-700;
        font-size: $font-size-base-xl;
      }
      svg {
        color: $gray-700;
      }
    }
    .vertical-line {
      width: 1px;
      height: 104px;
      background-color: $light-400;
    }
  }
}
.carousel-instructor {
  background: $light-500;
}
.instructors-wrapper {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  .skeleton-icon-wrapper {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    span {
      width: 100%;
    }
  }
}
.instructor-wrapper {
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: $light-200;
  padding: 24px;
  .instructor-img-wrapper {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    border-radius: 6px;
    img {
      width: 100%;
      height: inherit;
      object-fit: cover;
      border-radius: 6px;
    }
  }
  .instructor-name-wrapper {
    @extend .d-flex, .justify-content-between, .align-items-center;
    height: 36px;
  }
  .instructor-title {
    font-weight: bold;
    color: $black;
  }
  .instructor-short-bio {
    @include line-break(1);
    font-size: $font-size-base-sm;
    color: $gray-500;
  }
  .instructor-bio {
    @include line-break(4);
    font-size: $font-size-base-sm;
    color: $gray-700;
  }
  &:hover {
    cursor: pointer;
    border: 1px solid $light-300;
    .arrow-forward-btn {
      display: flex;
    }
  }
  .arrow-forward-btn {
    flex-shrink: 0;
  }
  .icons-bottom-wrapper {
    font-size: $font-size-base-sm;
    color: $gray-500;
    .pgn__icon {
      opacity: 0.5;
    }
  }
}
@media (max-width: 1024px) {
  .instructors-wrapper {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
}
@media (max-width: 768px) {
  .partner-page-breadcrumb {
    padding: 18px 0;
    border-bottom: 1px solid $light-300;
  }
  .partner-snapshot-wrapper {
    margin-top: 24px !important;
  }
  .banner-container {
    margin-bottom: 20px;
    .partner-img-wrapper {
      display: none;
    }
    .partner-logo-container {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      .partner-logo-wrapper {
        position: unset !important;
        width: 80px;
        height: 40px;
        padding: 0;
      }
      .share-icon {
        display: block !important;
      }
    }
  }
  .desc-partner-wrapper {
    .share-icon {
      display: none;
    }
  }
}
@media (max-width: 600px) {
  .vertical-line {
    display: none;
  }
  .partner-snapshot-wrapper {
    flex-direction: column;
  }
  .partner-page-breadcrumb {
    display: none;
  }
  .partner-logo-container {
    .partner-logo-wrapper {
      width: 116px !important;
      height: 66px !important;
    }
  }
}
