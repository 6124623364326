.instructor-header {
  @include custom-breadcrumb($light-300, $light-500);
  background: $background-dark-blue;
  padding-top: 32px;
  padding-bottom: 64px;

  h1 {
    color: $white;
  }

  .short-bio {
    color: $light-500;
    font-size: 1.25rem;
  }

  .long-bio-instructor {
    color: $light-300;
  }

  .instructor-share-icon {
    cursor: pointer;
    color: $light-500;
    opacity: 0.5;
  }

  .instructor-icons-wrapper {
    @extend .d-flex, .justify-content-between;
    @include flex(row, space-between, flex-start);
    .instructor-specs {
      @include flex();
    }
    color: $light-500 !important;
    opacity: 0.5;
    font-size: $font-size-base-sm;
  }

  p {
    color: $light-300;
  }

  .btn {
    color: $accent-a;

    &:active {
      color: $accent-a !important;
    }
  }

  .instructor-img-wrapper {
    height: 256px;
    width: 256px;
    margin-right: 5.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.5rem;
    background-color: $light-300;
  }

  .img-instructor {
    height: 100%;
    border-radius: 1.5rem;
  }
}

// .breadcrumb-container {
//   @include custom-breadcrumb($light-300, $light-500);
//   background: $background-dark-blue;
//   padding-top: 32px;
//   padding-bottom: 32px;
//   .list-inline {
//     margin-bottom: unset;
//   }
// }
.social-icon-skeleton-wrapper {
  span {
    display: flex;
    flex-direction: row;
  }

  .social-icon-skeleton {
    border-radius: 50%;
  }
}

.popular-courses-wrapper {
  margin-top: 48px;
  margin-bottom: 32px;
  justify-content: center;
  direction: initial;
}

.back-btn-wrapper {
  display: none !important;
  box-shadow: $box-shadow-mobile;
}

@media (max-width: 1440px) {
  .instructor-img-wrapper {
    margin-right: 1.75rem !important;
  }
}

@media (max-width: 768px) {
  .skeleton-container {
    flex-direction: column;

    .img {
      width: 60px !important;
      height: 60px !important;
      margin-bottom: 24px;
    }
  }

  .back-btn-wrapper {
    display: flex !important;
  }

  .instructor-header {
    padding-bottom: 32px;

    h1 {
      font-size: 36px;
    }
  }

  .breadcrumb-wrapper {
    display: none;
  }

  .popular-courses-wrapper {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .instructor-share-icon-mobile {
    display: block !important;
    color: #e1dddb;
    opacity: 0.5;
  }

  .instructor-share-icon {
    display: none;
  }

  .header-container {
    flex-direction: column;
  }

  .img-container {
    margin-bottom: 24px;
  }

  .instructor-img-wrapper {
    height: 60px !important;
    width: 60px !important;

    .img-instructor {
      border-radius: 6px;
    }
  }
}

@media (max-width: 600px) {
  .skeleton-container {
    .social-instructor-container {
      flex-direction: column;

      .social-icon-skeleton-wrapper {
        margin-top: 24px;
      }
    }
  }

  .popular-courses-wrapper {
    justify-content: unset;
  }

  // Temporarily disabled, will be deleted in the next merge
  // .instructor-icons-wrapper {
  //   flex-direction: column;
  //   align-items: unset !important;
  // }

  .social-container {
    margin-top: 24px;
  }
}
